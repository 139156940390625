import React, { useEffect, useState } from "react";
import Products from "./Products";
import { useDispatch, useSelector } from "react-redux";
import { getProList10 } from "../action/MainAction";
import Loading from "./Loading";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { loaderBtnsFunc } from "../redux/MainReducer";

const Title = () => {
  const dispatch = useDispatch();

  // For Loader spinner
  const loaderBtns = useSelector((state) => state.Data.loaderBtns);

  // Product list call by 10 items
  useEffect(() => {
    dispatch(getProList10());
  }, [dispatch]);

  const getProList10Value = useSelector(
    (state) => state.Data.getProList10Value
  );
  

  //  For state pagination page number
  const [currentPage, setCurrentPage] = useState(1);

  // Pagination func for get new results by page number
  const totalPages = Math.ceil(getProList10Value?.count / 10);

  const handlePageClick = async (pageNumb) => {
    // Scroll to the top of the page
    window.scroll({ top: 200, behavior: "smooth" });

    // Loader spinner start
    dispatch(loaderBtnsFunc());

    // Set current page
    setCurrentPage(pageNumb);

    if (getProList10Value.next) {
      // Find 'page=' and replace it with the new page number
      const updatedUrl = getProList10Value.next.replace(
        /(page=)\d+/,
        `$1${pageNumb}`
      );
      dispatch(getProList10(updatedUrl));
    } else if (pageNumb==1){
      dispatch(getProList10());
    } else {
      // If no next URL, use the previous URL
      dispatch(getProList10(getProList10Value.previous));
    }
  };

  return (
    <div className="title_container">
      {/* Отображаение товаров */}
      <div className="products_container">
        <div className="products_container">
          <div className="products_box">
            {!loaderBtns ? (
              <Loading />
            ) : (
              getProList10Value?.results?.map((data, i) => (
                <Products data={data} key={i} />
              ))
            )}
          </div>
          {loaderBtns ? (
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={(page) => handlePageClick(page)}
              pageLinkClassName="my_link"
              activeItemClassName="my_active"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Title;
