import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

import SearchResultsPageMap from "../components/SearchResultsPageMap";
import { useTranslation } from "react-i18next";

const SearchResultsPage = () => {

  const { t } = useTranslation()
  const location = useLocation();
  const results = location.state?.results || [];
  const inp = location.state?.inp || [];

 

  // Состояние для контроля количества отображаемых товаров
  const [visibleCount, setVisibleCount] = useState(20);

  // Функция для загрузки еще 20 товаров
  const showMoreProducts = () => {
    setVisibleCount((prev) => prev + 20);
  };

  // Состояния для сортировки
  const [sortedResults, setSortedResults] = useState([]);
  const [isSorted, setIsSorted] = useState(false);

   // Сортировка от дешевых к дорогим
   const sortAscending = () => {
    const sorted = [...results].sort((a, b) => a.price - b.price);
    setSortedResults(sorted);
    setIsSorted(true);
  };

  // Сортировка от дорогих к дешевым
  const sortDescending = () => {
    const sorted = [...results].sort((a, b) => b.price - a.price);
    setSortedResults(sorted);
    setIsSorted(true);
  };



  

  return (
    <>
      <Navbar />
      <Hero />
      <div className="head_of_search_results_page">
        <h1>{` ${t('searchPageh1')}: '${inp}' ( ${results.length} )`}</h1>
        <div className="sort-buttons" style={{marginBottom:'0'}}>
       
        <button  onClick={sortAscending}>{t("ascPrice")}</button>
          <button  onClick={sortDescending} >{t("descPrice")}</button>
        </div>
      </div>
      <div className="products_container">
        <div className="products_box">
          {results.lenght !== 0
            ? (isSorted ? sortedResults : results)
                ?.slice(0, visibleCount)
                .map((data, i) => <SearchResultsPageMap data={data} key={i} />)
            : ""}
        </div>

        <div className="show_more_btn">
          {visibleCount < results.length && (
            <button className="load-more-button" onClick={showMoreProducts}>
              {t("showMore")}
            </button>
          )}
        </div>
        <button></button>
      </div>
      <Footer />
    </>
  );
};

export default SearchResultsPage;
