import axios from "axios";
import { 
  categoryAtributesFunc,
  categoryListFunc,
  producstListFunc,
  productIDListFunc,
  productListFunc,
  carouselImagesFunc,
  cardsImageFunc,
  forVideoRekFunc,
  proList10Func,
  filterProductsFunc,
  searchResultFunc,
  contactInfoFunc,
  partnersImgFunc,
  instCardsfunc,
  resetProdID,
} from "../redux/MainReducer";

const staUrl = "https://api.leadtech.az/api";

const handleError = (error) => {
  console.error("API Error:", error);
  window.location.href = "/not-found";
};

export const getCategoryList = () => async (dispatch) => {
  try {
    const resp = await axios.get(`${staUrl}/categories`);
    dispatch(categoryListFunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};

export const getProductList = () => async (dispatch) => {
  try {
    const resp = await axios.get(`${staUrl}/product-list/`);
    dispatch(productListFunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};

export const getProList10 = (queryParams) => async (dispatch) => {
  try {
    const apiUrl = queryParams || `${staUrl}/products-list/`;
    const response = await axios.get(apiUrl);
    dispatch(proList10Func(response.data));
  } catch (error) {
    handleError(error);
  }
};

export const getCategoryAtributes = (id) => async (dispatch) => {
  if (!id) return;
  try {
    const response = await axios.get(`${staUrl}/category-attributes/${id}/`);
    dispatch(categoryAtributesFunc(response.data));
  } catch (error) {
    handleError(error);
  }
};

export const getProductsList = (id, pageNum) => async (dispatch) => {
  if (!id) return;
  try {
    const url = pageNum
      ? `${staUrl}/products-list/?category=${id}&page=${pageNum}`
      : `${staUrl}/products-list/?category=${id}`;
    const resp = await axios.get(url);
    dispatch(producstListFunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};

export const getFilteredProducts = (queryParams) => async (dispatch) => {
  if (!queryParams) return;
  try {
    const response = await axios.get(queryParams);
    dispatch(filterProductsFunc(response.data));
  } catch (error) {
    handleError(error);
  }
};

export const getProductIDList = (id) => async (dispatch) => {
  dispatch(resetProdID());
  try {
    const resp = await axios.get(`${staUrl}/product/${id}/`);
    dispatch(productIDListFunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};

export const getCarouselImages = () => async (dispatch) => {
  try {
    const resp = await axios.get(`${staUrl}/carousel/`);
    dispatch(carouselImagesFunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};

export const getCardsImage = () => async (dispatch) => {
  try {
    const resp = await axios.get(`${staUrl}/cards/`);
    dispatch(cardsImageFunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};

export const getForVideoRek = () => async (dispatch) => {
  try {
    const resp = await axios.get(`${staUrl}/videos/`);
    dispatch(forVideoRekFunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};

export const getSearchResult = () => async (dispatch) => {
  try {
    const resp = await axios.get(`${staUrl}/search/`);
    dispatch(searchResultFunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};

export const getContactInfo = () => async (dispatch) => {
  try {
    const resp = await axios.get(`${staUrl}/contact-info/`);
    dispatch(contactInfoFunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};

export const getPartnersImage = () => async (dispatch) => {
  try {
    const resp = await axios.get(`${staUrl}/collaborators/`);
    dispatch(partnersImgFunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};

export const getInstCards = () => async (dispatch) => {
  try {
    const resp = await axios.get(`${staUrl}/installmentscards/`);
    dispatch(instCardsfunc(resp.data));
  } catch (error) {
    handleError(error);
  }
};
