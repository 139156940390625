import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Footer from "../components/Footer";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <div className="container_404">
        <h1 className="title_404">404</h1>
        <p className="text_404">PAGE NOT FOUND :( </p>
        <Link to="/" className="button_404">
        RETURN TO HOME PAGE
        </Link>
      </div>

      <Footer />
    </>
  );
};

export default NotFound;
