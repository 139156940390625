import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import CategoryPage from "./pages/CategoryPage";
import SingleProdPage from "./pages/SingleProdPage";
import ContactPage from "./pages/ContactPage";
import SubCategoryPage from "./pages/SubCategoryPage";
// import SnowFall from "./components/SnowFall";
import { HelmetProvider } from "react-helmet-async";
import SearchResultsPage from "./pages/SearchResultsPage";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category/:name" element={<CategoryPage />} />
          <Route
            path="/category/subcategory/:subcategoryname"
            element={<SubCategoryPage />}
          />
          <Route
            path="/product/:productName/:id"
            element={<SingleProdPage />}
          />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/search-results" element={<SearchResultsPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        {/* <SnowFall /> */}
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
